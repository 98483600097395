import { environment } from "src/environments/environment";

export class Location {
  public id: number | string;
  public name: string;
  public details: string;
  public content: string;
  public latitude: number;
  public longitude: number;
  public directions: string;
  public colour: string;
  public category_ids: number[];

  constructor(
    id: number | string,
    name: string,
    details: string,
    latitude: number,
    longitude: number,
    colour: string,
    content: string,
    category_ids: number[]
  ) {
    this.id = id;
    this.name = name;
    this.details = details;
    this.latitude = latitude;
    this.longitude = longitude;
    this.colour = colour;
    this.content = content;
    this.category_ids = category_ids;
    this.setDirections();
  }

  private setDirections(): void {
    this.directions = `${environment.googleDirectionsUrl}${this.latitude},${this.longitude}`;
  }
}
