<ion-header>
  <ion-toolbar>
    <ion-title size="small">End User Licence Agreement</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content class="ion-padding">
  <img class="ion-padding" src="./assets/images/impact-apps.png" />
  <h1
    style="
      padding-top: 4pt;
      padding-left: 12pt;
      text-indent: 0pt;
      text-align: left;
    "
  >
    END USER LICENCE AGREEMENT
  </h1>
  <p style="text-indent: 0pt; text-align: left"><br /></p>
  <p style="text-indent: 0pt; text-align: left"><br /></p>
  <h1 style="padding-left: 12pt; text-indent: 0pt; text-align: left">
    KEY TERMS
  </h1>
  <p style="text-indent: 0pt; text-align: left"><br /></p>
  <table
    style="border-collapse: collapse; margin-left: 11.275pt"
    cellspacing="0"
  >
    <tr style="height: 30pt">
      <td style="width: 103pt" bgcolor="#F1F1F1">
        <p class="s1" style="text-indent: 0pt; text-align: left">Term</p>
      </td>
      <td style="width: 349pt" bgcolor="#F1F1F1">
        <p class="s1" style="text-indent: 0pt; text-align: left">Meaning</p>
      </td>
    </tr>
    <tr style="height: 30pt">
      <td
        style="
          width: 103pt;
          border-bottom-style: solid;
          border-bottom-width: 1pt;
          border-right-style: solid;
          border-right-width: 1pt;
        "
      >
        <p
          class="s1"
          style="padding-right: 9pt; text-indent: 0pt; text-align: left"
        >
          Impact Apps (Service Provider)
        </p>
      </td>
      <td
        style="
          width: 349pt;
          border-left-style: solid;
          border-left-width: 1pt;
          border-bottom-style: solid;
          border-bottom-width: 1pt;
        "
      >
        <p
          class="s2"
          style="padding-right: 47pt; text-indent: 0pt; text-align: left"
        >
          means Impact Apps Pty Ltd ABN 57 169 306 227, the developer and owner
          of the Application.
        </p>
      </td>
    </tr>
    <tr style="height: 31pt">
      <td
        style="
          width: 103pt;
          border-top-style: solid;
          border-top-width: 1pt;
          border-bottom-style: solid;
          border-bottom-width: 1pt;
          border-right-style: solid;
          border-right-width: 1pt;
        "
      >
        <p class="s1" style="text-indent: 0pt; text-align: left">End User</p>
      </td>
      <td
        style="
          width: 349pt;
          border-top-style: solid;
          border-top-width: 1pt;
          border-left-style: solid;
          border-left-width: 1pt;
          border-bottom-style: solid;
          border-bottom-width: 1pt;
        "
      >
        <p
          class="s2"
          style="text-indent: 0pt; line-height: 10pt; text-align: left"
        >
          means you, or any person to whom the Head Licence Holder provides
          access
        </p>
        <p
          class="s2"
          style="
            padding-right: 7pt;
            text-indent: 0pt;
            line-height: 11pt;
            text-align: left;
          "
        >
          to the Application and this EULA, including any personnel of the Head
          Licence Holder and members of the community with the Head Licence
          Holder governs.
        </p>
      </td>
    </tr>
    <tr style="height: 30pt">
      <td
        style="
          width: 103pt;
          border-top-style: solid;
          border-top-width: 1pt;
          border-bottom-style: solid;
          border-bottom-width: 1pt;
          border-right-style: solid;
          border-right-width: 1pt;
        "
      >
        <p
          class="s1"
          style="text-indent: 0pt; line-height: 10pt; text-align: left"
        >
          Head Licence Holder
        </p>
      </td>
      <td
        style="
          width: 349pt;
          border-top-style: solid;
          border-top-width: 1pt;
          border-left-style: solid;
          border-left-width: 1pt;
          border-bottom-style: solid;
          border-bottom-width: 1pt;
        "
      >
        <p
          class="s2"
          style="padding-right: 6pt; text-indent: 0pt; text-align: left"
        >
          means the provider with which Impact Apps has entered into an
          agreement to provide the Application, who in turn provides the
          Application to you.
        </p>
      </td>
    </tr>
    <tr style="height: 31pt">
      <td
        style="
          width: 103pt;
          border-top-style: solid;
          border-top-width: 1pt;
          border-bottom-style: solid;
          border-bottom-width: 1pt;
          border-right-style: solid;
          border-right-width: 1pt;
        "
      >
        <p
          class="s1"
          style="padding-right: 15pt; text-indent: 0pt; text-align: left"
        >
          Intellectual Property Rights
        </p>
      </td>
      <td
        style="
          width: 349pt;
          border-top-style: solid;
          border-top-width: 1pt;
          border-left-style: solid;
          border-left-width: 1pt;
          border-bottom-style: solid;
          border-bottom-width: 1pt;
        "
      >
        <p class="s2" style="text-indent: 0pt; text-align: left">
          means all copyright, trade mark, design, patent, moral rights,
          confidential and other proprietary rights, and any other rights to
          registration of such rights whether created
        </p>
        <p
          class="s2"
          style="text-indent: 0pt; line-height: 9pt; text-align: left"
        >
          before or after the date of this EULA both in Australia and throughout
          the world.
        </p>
      </td>
    </tr>
    <tr style="height: 30pt">
      <td
        style="
          width: 103pt;
          border-top-style: solid;
          border-top-width: 1pt;
          border-bottom-style: solid;
          border-bottom-width: 1pt;
          border-right-style: solid;
          border-right-width: 1pt;
        "
      >
        <p class="s1" style="text-indent: 0pt; text-align: left">Application</p>
      </td>
      <td
        style="
          width: 349pt;
          border-top-style: solid;
          border-top-width: 1pt;
          border-left-style: solid;
          border-left-width: 1pt;
          border-bottom-style: solid;
          border-bottom-width: 1pt;
        "
      >
        <p class="s2" style="text-indent: 0pt; text-align: left">
          means the Application developed by Impact Apps.
        </p>
      </td>
    </tr>
  </table>
  <p style="text-indent: 0pt; text-align: left"><br /></p>
  <ol id="l1">
    <li>
      <h1 style="padding-left: 40pt; text-indent: -28pt; text-align: left">
        APPLICABILITY AND DEEMED ACCEPTANCE
      </h1>
      <p style="text-indent: 0pt; text-align: left"><br /></p>
      <ol id="l2">
        <li>
          <p style="padding-left: 61pt; text-indent: -21pt; text-align: left">
            This EULA applies to any End Users of the Application. You agree to,
            and will be deemed to have accepted, this EULA when you access the
            Application.
          </p>
        </li>
        <li>
          <p style="padding-left: 61pt; text-indent: -21pt; text-align: left">
            By accessing the Application, you irrevocably consent to the terms
            of this EULA and represent and warrant that you will comply with the
            scope and restrictions of the licence to the Application provided
            under this EULA. If you do not accept this EULA, you must not
            access, use or otherwise view
          </p>
          <p
            style="
              padding-left: 61pt;
              text-indent: 0pt;
              line-height: 10pt;
              text-align: left;
            "
          >
            the Application.
          </p>
        </li>
        <li>
          <p style="padding-left: 61pt; text-indent: -21pt; text-align: left">
            This EULA commences on the date you are granted access to the
            Application by the Head Licence Holder and will continue until your
            access to the Application is terminated by the Head Licence Holder
            or Impact Apps.
          </p>
          <p style="text-indent: 0pt; text-align: left"><br /></p>
        </li>
      </ol>
    </li>
    <li>
      <h1 style="padding-left: 40pt; text-indent: -28pt; text-align: left">
        USE OF APPLICATION
      </h1>
    </li>
  </ol>
  <ol id="l3">
    <li>
      <p
        style="
          padding-left: 40pt;
          text-indent: -28pt;
          line-height: 10pt;
          text-align: left;
        "
      >
        GRANT OF LICENCE
      </p>
      <p style="padding-left: 40pt; text-indent: -28pt; text-align: left">
        You are granted a revocable, worldwide, royalty-free licence to use the
        Application for the purpose described in the details at the time you
        download the Application (<b>Purpose</b>). You must only use the
        Application:
      </p>
      <ol id="l4">
        <li>
          <p
            style="
              padding-left: 40pt;
              text-indent: -28pt;
              line-height: 10pt;
              text-align: left;
            "
          >
            in accordance with the limitations of the Purpose;
          </p>
        </li>
        <li>
          <p
            style="
              padding-left: 40pt;
              text-indent: -28pt;
              line-height: 10pt;
              text-align: left;
            "
          >
            <span class="p"
              >in a manner that is consistent and compliant with clause </span
            ><span class="s3" style="background-color: #e0e2e6">2.2</span
            ><span class="p">; and</span>
          </p>
        </li>
        <li>
          <p style="padding-left: 40pt; text-indent: -28pt; text-align: left">
            in compliance with any other restrictions as notified to you by the
            Head Licence Holder and/ or Impact Apps from time to time.
          </p>
        </li>
      </ol>
    </li>
    <li>
      <p style="padding-left: 12pt; text-indent: 0pt; text-align: left">
        RESTRICTIONS ON LICENCE You must not:
      </p>
      <ol id="l5">
        <li>
          <p
            style="
              padding-left: 40pt;
              text-indent: -28pt;
              line-height: 10pt;
              text-align: left;
            "
          >
            upload sensitive information or commercial secrets using the
            Application;
          </p>
        </li>
        <li>
          <p style="padding-left: 40pt; text-indent: -28pt; text-align: left">
            upload any inappropriate, offensive, illicit, illegal, pornographic,
            sexist, homophobic or racist material using the Application;
          </p>
        </li>
        <li>
          <p
            style="
              padding-left: 40pt;
              text-indent: -28pt;
              line-height: 10pt;
              text-align: left;
            "
          >
            upload any material that is owned or copyrighted by a third party;
          </p>
        </li>
        <li>
          <p
            style="
              padding-left: 40pt;
              text-indent: -28pt;
              line-height: 10pt;
              text-align: left;
            "
          >
            make copies of the Documentation or the Application;
          </p>
        </li>
        <li>
          <p
            style="
              padding-left: 40pt;
              text-indent: -28pt;
              line-height: 10pt;
              text-align: left;
            "
          >
            adapt, modify or tamper in any way with the Application;
          </p>
        </li>
        <li>
          <p style="padding-left: 40pt; text-indent: -28pt; text-align: left">
            remove or alter any copyright, trade mark or other notice on or
            forming part of the Application or Documentation;
          </p>
        </li>
        <li>
          <p
            style="
              padding-top: 4pt;
              padding-left: 40pt;
              text-indent: -28pt;
              text-align: left;
            "
          >
            act in any way that may harm our reputation or that of associated or
            interested parties or do anything at all contrary to the interests
            of us or the Application;
          </p>
        </li>
        <li>
          <p
            style="
              padding-left: 40pt;
              text-indent: -28pt;
              line-height: 10pt;
              text-align: left;
            "
          >
            use the Application in a way which infringes the Intellectual
            Property Rights of any third party;
          </p>
        </li>
        <li>
          <p
            style="
              padding-left: 40pt;
              text-indent: -28pt;
              line-height: 10pt;
              text-align: left;
            "
          >
            create derivative works from or translate the Application or
            Documentation;
          </p>
        </li>
        <li>
          <p style="padding-left: 40pt; text-indent: -28pt; text-align: left">
            publish or otherwise communicate the Application or Documentation to
            the public, including by making it available online or sharing it
            with third parties;
          </p>
        </li>
        <li>
          <p style="padding-left: 40pt; text-indent: -28pt; text-align: left">
            sell, loan, transfer, sub-licence, hire or otherwise dispose of the
            Application or Documentation to any third party;
          </p>
        </li>
        <li>
          <p style="padding-left: 40pt; text-indent: -28pt; text-align: left">
            decompile or reverse engineer the Application or any part of it, or
            otherwise attempt to derive its source code; or
          </p>
        </li>
        <li>
          <p
            style="
              padding-left: 40pt;
              text-indent: -28pt;
              line-height: 10pt;
              text-align: left;
            "
          >
            attempt to circumvent any technological protection mechanism or
            other security feature of the Application.
          </p>
        </li>
      </ol>
    </li>
    <li>
      <p
        style="
          padding-left: 40pt;
          text-indent: -28pt;
          line-height: 10pt;
          text-align: left;
        "
      >
        LIMITATIONS OF APPLICATION
      </p>
      <p
        style="
          padding-left: 12pt;
          text-indent: 0pt;
          line-height: 10pt;
          text-align: left;
        "
      >
        Impact Apps does not guarantee, and makes no warranties, to the extent
        permitted by law, that:
      </p>
      <ol id="l6">
        <li>
          <p
            style="
              padding-left: 40pt;
              text-indent: -28pt;
              line-height: 10pt;
              text-align: left;
            "
          >
            the Application will be free from errors or defects at all time;
          </p>
        </li>
        <li>
          <p
            style="
              padding-left: 40pt;
              text-indent: -28pt;
              line-height: 10pt;
              text-align: left;
            "
          >
            the Application will be accessible or available at all times; or
          </p>
        </li>
        <li>
          <p
            style="
              padding-left: 40pt;
              text-indent: -28pt;
              line-height: 10pt;
              text-align: left;
            "
          >
            any information provided through the Application is accurate, true,
            up-to-date or not misleading.
          </p>
        </li>
      </ol>
    </li>
  </ol>
  <ol id="l7">
    <li>
      <h1
        style="
          padding-left: 40pt;
          text-indent: -28pt;
          line-height: 10pt;
          text-align: left;
        "
      >
        DISCLAIMERS
      </h1>
      <ol id="l8">
        <li>
          <p style="padding-left: 40pt; text-indent: -28pt; text-align: left">
            Impact Apps does not accept responsibility for any unauthorised use,
            destruction, loss, damage or alteration to your data or information,
            your computer systems, mobile phones or other electronic devices
            arising in connection with use of the Application.
          </p>
        </li>
        <li>
          <p
            style="
              padding-left: 40pt;
              text-indent: -28pt;
              line-height: 10pt;
              text-align: left;
            "
          >
            You must take your own precautions to ensure that the process which
            you employ for accessing
          </p>
          <p style="padding-left: 40pt; text-indent: 0pt; text-align: left">
            the Application does not expose you to the risk of hacking, malware,
            ransomware, viruses, malicious computer code or other forms of
            interference.
          </p>
        </li>
        <li>
          <p style="padding-left: 40pt; text-indent: -28pt; text-align: left">
            To the maximum extent permitted by applicable law, Impact Apps
            limits all liability to any person for loss or damage of any kind,
            however arising whether in contract, tort (including negligence),
            statute, equity, indemnity or otherwise, arising from or relating in
            any way to the Application to the amount paid to download the
            Application (if any) in aggregate. This includes the transmission of
            any computer virus.
          </p>
        </li>
        <li>
          <p style="padding-left: 40pt; text-indent: -28pt; text-align: left">
            You indemnify Impact Apps and its employees, agents and contractors
            (<b>Personnel</b>) in respect of all liability for loss, damage or
            injury which may be suffered by any person arising from, or in
            connection with, your use of the Application or breach of this EULA
            (or both, as the case may be).
          </p>
        </li>
        <li>
          <p style="padding-left: 40pt; text-indent: -28pt; text-align: left">
            You acknowledge and agree that Impact Apps will have no liability
            for any act or omission by you which results in or contributes to
            damage, loss or expense suffered by you or another user in
            connection with the use of the Application and you indemnify Impact
            Apps for any such damage, loss or expense.
          </p>
        </li>
        <li>
          <p style="padding-left: 40pt; text-indent: -28pt; text-align: left">
            All express or implied representations and warranties given by
            Impact Apps or its Personnel are, to the maximum extent permitted by
            applicable law, excluded. Where any law implies a condition,
            warranty or guarantee into this EULA which may not lawfully be
            excluded, then to the maximum extent permitted by applicable law,
            Impact Apps (and its Personnel’s) liability for breach of that
            non-excludable condition, warranty or guarantee will, at our option,
            be limited to:
          </p>
          <p
            style="
              padding-left: 7pt;
              text-indent: 0pt;
              line-height: 10pt;
              text-align: left;
            "
          >
            i.in the case of goods, their replacement or the supply of
            equivalent goods or their repair; and
          </p>
          <p style="padding-left: 40pt; text-indent: -34pt; text-align: left">
            ii.in the case of services, the supply of the services again, or the
            payment of the cost of having them supplied again.
          </p>
        </li>
        <li>
          <p style="padding-left: 40pt; text-indent: -28pt; text-align: left">
            To the maximum extent permitted under applicable law, including the
            <i>Competition and Consumer Act 2010 </i>(Cth), under no
            circumstances will Impact Apps or its Personnel be liable for any
            incidental, special or consequential loss or damages, or damages for
            loss of data, business or business opportunity, goodwill,
            anticipated savings, profits or revenue arising under or in
            connection with the Application, this EULA or their subject matter.
          </p>
        </li>
      </ol>
    </li>
    <li>
      <h1 style="padding-left: 40pt; text-indent: -28pt; text-align: left">
        TERMINATION
      </h1>
    </li>
  </ol>
  <ol id="l9">
    <li>
      <p
        style="
          padding-left: 40pt;
          text-indent: -28pt;
          line-height: 10pt;
          text-align: left;
        "
      >
        AUTOMATIC TERMINATION
      </p>
      <p style="padding-left: 40pt; text-indent: -28pt; text-align: left">
        This EULA will be automatically terminated, and your licence to the
        Application will be immediately revoked, if the agreement between Impact
        Apps and the Head Licence Holder for the Application expires, is
        terminated or otherwise comes to an end.
      </p>
    </li>
    <li>
      <p
        style="
          padding-left: 40pt;
          text-indent: -28pt;
          line-height: 10pt;
          text-align: left;
        "
      >
        TERMINATION BY IMPACT APPS OR HEAD LICENCE HOLDER
      </p>
      <p
        style="
          padding-left: 12pt;
          text-indent: 0pt;
          line-height: 10pt;
          text-align: left;
        "
      >
        Impact Apps or the Head Licence Holder (or both) may terminate this EULA
        immediately by notice to you if:
      </p>
      <ol id="l10">
        <li>
          <p
            style="
              padding-left: 40pt;
              text-indent: -28pt;
              line-height: 10pt;
              text-align: left;
            "
          >
            you are in breach of any term of this EULA; or
          </p>
        </li>
        <li>
          <p style="padding-left: 40pt; text-indent: -28pt; text-align: left">
            you commit, or Impact Apps or the Head Licence Holder reasonably
            suspects that you may commit, any breach of this EULA.
          </p>
        </li>
      </ol>
    </li>
    <li>
      <p
        style="
          padding-left: 40pt;
          text-indent: -28pt;
          line-height: 10pt;
          text-align: left;
        "
      >
        EFFECT OF EXPIRY OR TERMINATION
      </p>
      <ol id="l11">
        <li>
          <p
            style="
              padding-left: 40pt;
              text-indent: -28pt;
              line-height: 10pt;
              text-align: left;
            "
          >
            In the event of expiry or termination of this EULA, you must:
          </p>
          <ol id="l12">
            <li>
              <p
                style="
                  padding-left: 12pt;
                  text-indent: -4pt;
                  line-height: 10pt;
                  text-align: left;
                "
              >
                immediately cease using the Application; and
              </p>
            </li>
            <li>
              <p
                style="padding-left: 40pt; text-indent: -34pt; text-align: left"
              >
                remove the Application from all materials in your care, custody
                or control that feature the Application, and, if the Application
                cannot be removed, then at Impact Apps’s option, return or
                destroy all such material.
              </p>
            </li>
          </ol>
        </li>
        <li>
          <p style="padding-left: 40pt; text-indent: -28pt; text-align: left">
            Termination of this EULA will not affect any rights accruing to any
            party to the date of termination nor any obligation performed to the
            date of termination or any obligation which expressly or impliedly
            survives termination of this EULA.
          </p>
        </li>
      </ol>
    </li>
    <li>
      <p
        style="
          padding-left: 40pt;
          text-indent: -28pt;
          line-height: 10pt;
          text-align: left;
        "
      >
        YOUR DATA ON TERMINATION
      </p>
      <p
        style="
          padding-top: 4pt;
          padding-left: 40pt;
          text-indent: -28pt;
          text-align: left;
        "
      >
        You are solely responsible for removing any information you store in the
        Application prior to termination of this EULA. Impact Apps will not be
        liable to you for any loss of your or any other user’s data or
        information upon termination of this EULA.
      </p>
    </li>
    <li>
      <h1
        style="
          padding-left: 40pt;
          text-indent: -28pt;
          line-height: 10pt;
          text-align: left;
        "
      >
        GENERAL
      </h1>
    </li>
  </ol>
  <ol id="l13">
    <li>
      <p
        style="
          padding-left: 40pt;
          text-indent: -28pt;
          line-height: 10pt;
          text-align: left;
        "
      >
        GOVERNING LAW AND JURISDICTION
      </p>
      <p style="padding-left: 40pt; text-indent: -28pt; text-align: left">
        This EULA is governed by the law applying in New South Wales, Australia.
        Each party irrevocably submits to the exclusive jurisdiction of the
        courts of New South Wales, Australia and courts of appeal from them in
        respect of any proceedings arising out of or in connection with this
        EULA. Each party irrevocably waives any objection to the venue of any
        legal process on the basis that the process has been brought in an
        inconvenient forum.
      </p>
    </li>
    <li>
      <p
        style="
          padding-left: 40pt;
          text-indent: -28pt;
          line-height: 10pt;
          text-align: left;
        "
      >
        WAIVER
      </p>
      <p style="padding-left: 40pt; text-indent: -28pt; text-align: left">
        No party to this EULA may rely on the words or conduct of any other
        party as a waiver of any right unless the waiver is in writing and
        signed by the party granting the waiver.
      </p>
    </li>
    <li>
      <p
        style="
          padding-left: 40pt;
          text-indent: -28pt;
          line-height: 10pt;
          text-align: left;
        "
      >
        FURTHER ACTS AND DOCUMENTS
      </p>
      <p style="padding-left: 40pt; text-indent: -28pt; text-align: left">
        Each party must promptly do all further acts and execute and deliver all
        further documents required by law or reasonably requested by another
        party to give effect to this EULA.
      </p>
    </li>
    <li>
      <p
        style="
          padding-left: 40pt;
          text-indent: -28pt;
          line-height: 10pt;
          text-align: left;
        "
      >
        ASSIGNMENT
      </p>
      <p style="padding-left: 40pt; text-indent: -28pt; text-align: left">
        You cannot assign, novate or otherwise transfer your rights or
        obligations under this EULA without Impact Apps’ prior consent.
      </p>
    </li>
    <li>
      <p
        style="
          padding-left: 40pt;
          text-indent: -28pt;
          line-height: 10pt;
          text-align: left;
        "
      >
        ENTIRE AGREEMENT
      </p>
    </li>
  </ol>
  <p style="padding-left: 40pt; text-indent: -28pt; text-align: left">
    This EULA embodies the entire agreement between the parties and supersede
    any prior negotiation, conduct, arrangement, understanding or agreement,
    express or implied, in relation to the subject matter of this EULA.
  </p>
</ion-content>

<ion-footer class="ion-padding">
  <ion-button (click)="agree()" expand="block">Accept</ion-button>
</ion-footer>
