import { Component, OnInit } from "@angular/core";
import { FirebaseAnalytics } from "@capacitor-community/firebase-analytics";
import { ModalController } from "@ionic/angular";
import { ApiService } from "src/app/services/api.service";

@Component({
  selector: "app-eula",
  templateUrl: "./eula.page.html",
  styleUrls: ["./eula.page.scss"],
})
export class EulaPage implements OnInit {
  constructor(private modalCtrl: ModalController, private api: ApiService) {}

  public ngOnInit(): void {
    FirebaseAnalytics.setScreenName({
      screenName: "EULA",
      nameOverride: "EulaPage",
    });
  }

  public agree(): void {
    this.api.setEulaAccepted(true);
    this.modalCtrl.dismiss();
  }
}
