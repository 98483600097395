import { Injectable } from "@angular/core";
import { CanActivateChild } from "@angular/router";
import { ApiService } from "./services/api.service";

@Injectable({
  providedIn: "root",
})
export class AppGuard implements CanActivateChild {
  constructor(private api: ApiService) {}

  public async canActivateChild(): Promise<boolean> {
    await this.api.ready();
    return true;
  }
}
