import { Injectable } from "@angular/core";
import { ToastController } from "@ionic/angular";

@Injectable({
  providedIn: "root",
})
export class ErrorService {
  constructor(private toastCtrl: ToastController) {}

  public handleError(e: Error, message?: string): void {
    console.error(e);
    this.showToast(message ? message : e.message);
  }

  public showToast(message: string) {
    this.toastCtrl
      .create({
        message,
        duration: 5000,
        buttons: [
          {
            text: "Close",
            role: "cancel",
          },
        ],
      })
      .then((toast) => toast.present());
  }
}
