import { WeekDay } from "@angular/common";
import { EventApi } from "@fullcalendar/angular";
import { Property } from "./property.model";

export enum EventType {
  waste = "waste",
  recycle = "recycle",
  organic = "organic",
  special = "special",
  crc = "crc",
  cds = "cds",
  dropOff = "waste_drop_off",
  cleanUp = "clean_up",
}

export interface CalendarEvent extends EventApi {
  id: string;
  name: string;
  description: string;
  date: string;
  daysOfWeek: number[];
  event_type: EventType;
  startHalfDay: boolean;
  start_date: string;
  start: Date;
  borderColor: string;
  allDay: boolean;
  url: string;
  end: Date;
  color: string;
  textColor: string;
  property?: Property;
  dow: WeekDay[];
}
