import { Weekday } from "@capacitor/local-notifications";
import { EventType } from "./calendar-event.model";

export enum ReminderOffset {
  dayOf = 0,
  dayBefore = 1,
}

export interface Reminder {
  offset: ReminderOffset;
  hours: number;
  minutes: number;
  recurring: boolean;
  eventTypes: EventType[];
  collectionsDays?: Weekday[];
}
