import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class IframeService {
  constructor() {}

  public inIframe() {
    try {
      return window.self !== window.top;
    } catch (e) {
      return true;
    }
  }
}
