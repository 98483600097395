import { Component, Input, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Browser } from "@capacitor/browser";
import {
  LoadingController,
  ModalController,
  NavController,
} from "@ionic/angular";

@Component({
  selector: "app-external-redirect",
  templateUrl: "./external-redirect.component.html",
})
export class ExternalRedirectComponent implements OnInit {
  @Input() url: string;

  constructor(
    private route: ActivatedRoute,
    private modalCtrl: ModalController,
    private loadingCtrl: LoadingController,
    private navCtrl: NavController
  ) {}

  public async ngOnInit(): Promise<void> {
    const loader = await this.loadingCtrl.create();
    loader.present();
    const redirect = this.url || this.route.snapshot.params.url;
    if (redirect) {
      Browser.open({ url: redirect });
    } else {
      this.navCtrl.back();
    }
    const modal = await this.modalCtrl.getTop();
    if (modal) {
      loader.dismiss();
      this.modalCtrl.dismiss();
    } else {
      loader.dismiss();
      this.navCtrl.back();
    }
  }
}
